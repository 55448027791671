import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/home/HomeView.vue";
import ProductView from "@/views/product/ProductView";
import SCRMView from "@/views/solution/SCRMView/SCRMView";
import kwSolutionView from "@/views/solution/kwSolutionView/kwSolutionView";
import AboutusView from "@/views/aboutus/AboutusView";
import JoinusView from "@/views/joinus/JoinusView";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "*",
  //   redirect: "/",
  // },
  {
    path: '/',
    name: 'layout',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: "/",
        component: HomeView,
      },
      {
        path: "/home",
        component: HomeView,
      },
      {
        path: "/product",
        component: ProductView,
      },
      {
        path: "/SCRM",
        component: SCRMView,
      },
      {
        path: "/kwsolution",
        component: kwSolutionView,
      },
      {
        path: "/aboutus",
        component: AboutusView,
      },
      {
        path: "/joinus",
        component: JoinusView,
      },
    ]
  },


];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
