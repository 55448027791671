<template>
  <div class="aboutBox" ref="aboutBox" id="aboutBox">
    <div class="headBox">
      <div class="centerBox">
        <div class="head-tit">选择我们 共创辉煌</div>
        <div class="head-con">
          助力企业向数字化转型升级，通过科技驱动商业 <br />
          革新，让商业变得更智慧
        </div>
        <div class="useBox" @click="zxClick">立即咨询</div>
      </div>
    </div>
    <div class="conBox" ref="conBox">
      <div class="con-tit" style="margin-top: 65px; margin-bottom: 40px">
        公司简介
      </div>
      <div class="con-row">
        <div class="gs-left">
          <div class="gs-tit">深圳一飞立科技有限公司</div>
          <div class="gs-con">
            深圳一飞立科技有限公司是一家集信息技术咨询、大数据处理与软件研发于一体的科技公司，专注于“以敏捷应用重构业务生态”，致力于深耕智能装备行业，公司由一群来自腾讯、阿里、华为等不同领域的优秀专家汇聚而成，秉承以人为本，坚持创新发展的经营理念，助力企业的转型升级。
            <br />
            深圳一飞立未来将充分利用企业数字化转型升级的契机，以客户为中心，建立以大数据为载体的“数字化、智能化”管理模式。将“大数据、云平台、物联网”的服务与管理模式贯穿于市场、营销、服务、研发、制作、供应链等各个模块，形成营销数字化服务智能化、产品互联化、行业生态化的模式，把产业的升级推向新的高度。
          </div>
        </div>
        <div class="gs-right">
          <Map />
        </div>
      </div>
      <div class="con-tit" style="margin-top: 80px; margin-bottom: 40px">
        我们的优势
      </div>
      <div class="con-row">
        <img src="@/assets/ab-1.png" alt="" class="imgItem" />
        <img src="@/assets/ab-2.png" alt="" class="imgItem" />
        <img src="@/assets/ab-3.png" alt="" class="imgItem" />
      </div>
      <div class="con-tit" style="margin-top: 78px; margin-bottom: 20px">
        服务流程
      </div>
      <div class="con-toa">
        需求设计，产品开发，产品部署，服务器选购域名备案一站式服务
      </div>
      <div class="con-row">
        <div class="rowItem">
          <img src="@/assets/ab-lc1.png" alt="" class="rowItem-img" />
          <div class="rowItem-text">
            客户需求沟通确认设计要求，功能要求，定制化服务。
          </div>
        </div>
        <div class="rowItem">
          <img src="@/assets/ab-lc2.png" alt="" class="rowItem-img" />
          <div class="rowItem-text">
            根据客户提出的需求，评估相应的时间与费用。
          </div>
        </div>
        <div class="rowItem">
          <img src="@/assets/ab-lc3.png" alt="" class="rowItem-img" />
          <div class="rowItem-text">
            完成产品初稿设计，包括图标设计，页面风格，主题色调确认。
          </div>
        </div>
      </div>
      <div class="con-row">
        <div class="rowItem">
          <img src="@/assets/ab-lc4.png" alt="" class="rowItem-img" />
          <div class="rowItem-text">
            完成数据库建设、系统功能模块开发以及前后端系统整合。
          </div>
        </div>
        <div class="rowItem">
          <img src="@/assets/ab-lc5.png" alt="" class="rowItem-img" />
          <div class="rowItem-text">产品使用指导，功能需求优化、操作视频。</div>
        </div>
        <div class="rowItem">
          <img src="@/assets/ab-lc6.png" alt="" class="rowItem-img" />
          <div class="rowItem-btn" @click="zxClick">立即咨询</div>
        </div>
      </div>
      <div class="con-tit" style="margin-top: 65px; margin-bottom: 40px">
        公司历程
      </div>
      <div class="con-row">
        <div class="zx-left"></div>
        <div class="zx-right">
          <!-- <div class="zx-rh">
            <ul>
              <li>飞立SCRM，智能化销售漏斗，直观感受成交商机汇总！</li>
              <li>扎根装备制造与工程机械，专注于“以敏捷应用重构业务生态”</li>
              <li>智慧商城链接企业微信私域化</li>
              <li>数字化企业转型解决方案提供商，与合作伙伴开放合作</li>
              <li>关于“SCRM”你不知道的那些事！</li>
            </ul>
          </div> -->
          <!-- <div class="zx-btn">查看更多</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from "@/components/map/index.vue";
export default {
  name: "AboutusView",
  components: {
    Map,
  },
  data() {
    return {
      acitveRh: 1,
    };
  },
  methods: {
    addrClick(num) {
      this.acitveRh = num;
    },
    zxClick() {
      this.$parent.test();
    },
  },
};
</script>

<style scoped>
.gs-right {
  width: 576px;
}
.flexBox {
  display: flex;
}
.rowItem-btn {
  font-weight: 500;
  font-size: 18px;
  color: #0c5aff;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
}
.gsImg {
  width: 576px;
  height: 320px;
  margin-top: 21px;
}
.rh-active {
  width: 108px;
  height: 32px;
  background: #dae6ff;
  border-radius: 4px;
  font-size: 12px;
  color: #0c5aff;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
}
.rh-item {
  width: 108px;
  height: 32px;
  background: #eaeffa;
  border-radius: 4px;
  font-size: 12px;
  color: #969eb4;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
.zx-btn {
  width: 108px;
  height: 32px;
  background: #dae6ff;
  border-radius: 4px;
  font-size: 12px;
  color: #0c5aff;
  text-align: center;
  line-height: 32px;
  margin-top: 18px;
}
.zx-rh li {
  list-style-type: disc;
}
.zx-rh {
  width: 793px;
  height: 190px;
  background: #ffffff;
  border: 1px solid #d8e1ff;
  box-shadow: 0 10px 20px 0 rgba(236, 236, 248, 0.5);
  border-radius: 4px;
  font-size: 18px;
  color: rgba(126, 131, 149, 0.85);
  line-height: 30px;
}
.zx-right {
  background: url("@/assets/ab-lc.png") no-repeat;
  background-size: 100% 100%;
  width: 793px;
  height: 302px;
}
.zx-left {
  background: url("@/assets/ab-gszx.png") no-repeat;
  background-size: 100% 100%;
  width: 384px;
  height: 302px;
}
.gs-con {
  padding-left: 31px;
  padding-top: 27px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.6px;
  line-height: 24px;
}
.gs-tit {
  padding-left: 31px;
  padding-top: 68px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
.gs-left {
  background: url("@/assets/ab-gsbg.png") no-repeat;
  background-size: 100% 100%;
  width: 599px;
  height: 374px;
}
.aboutBox {
  background: #fefefe;
  margin-bottom: 80px;
  min-width: 1440px;
  width: 100%;
}
.rowItem-text {
  width: 350px;
  height: 50px;
  margin-left: 16px;
  font-weight: 400;
  font-size: 18px;
  color: #7e8395;
}
.rowItem-img {
  width: 384px;
  height: 180px;
}
.rowItem {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
}
.con-toa {
  font-size: 18px;
  color: #7e8395;
  text-align: center;
  line-height: 18px;
  height: 18px;
  margin-bottom: 48px;
}
.imgItem {
  width: 384px;
  /* width: 26.66%; */
  height: 320px;
}
.con-row {
  display: flex;
  justify-content: space-between;
}
.headBox {
  width: 100%;
  height: 528px;
  background: url("@/assets/ab-hd.jpeg") no-repeat;
  background-size: 100% 100%;
  padding-top: 154px;
  box-sizing: border-box;
  color: #ffffff;
}
.head-tit {
  font-weight: 500;
  font-size: 44px;
  height: 62px;
  line-height: 62px;
}
.head-con {
  padding-top: 21px;
  font-size: 18px;
  margin-bottom: 94px;
}
.useBox {
  width: 168px;
  height: 56px;
  background: #0c5aff;
  border-radius: 8px;
  text-align: center;
  line-height: 56px;
  color: #ffffff;
  font-size: 18px;
}
.conBox {
  /* padding: 0 86px; */
  width: 1200px;
  margin: 0 auto;
}
.con-tit {
  font-weight: 500;
  font-size: 36px;
  color: #191c27;
  text-align: center;
  height: 50px;
  line-height: 50px;
}
</style>
