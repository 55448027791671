import { render, staticRenderFns } from "./kwSolutionView.vue?vue&type=template&id=61b4fb5a&scoped=true&"
import script from "./kwSolutionView.vue?vue&type=script&lang=js&"
export * from "./kwSolutionView.vue?vue&type=script&lang=js&"
import style0 from "./kwSolutionView.vue?vue&type=style&index=0&id=61b4fb5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b4fb5a",
  null
  
)

export default component.exports