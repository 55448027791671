<template>
  <div class="kwView containerBox">
    <div class="top">
      <div class="centerBox">
        <div class="title">{{ topTitle }}</div>
        <div class="subtitle">
          为企业库位提供数字化集中管理，满足供应链上下游的需 <br />
          求，提高企业效益
        </div>
      </div>
    </div>

    <div class="main">
      <div class="first">
        <h3 class="title-item">{{ titleItem[0] }}</h3>
        <div class="subtitle-item">{{ subtitleItem[0] }}</div>
        <div class="IconBox">
          <div v-for="(item, id) in mainIconArr" :key="id" class="iconBox-item">
            <img class="Icon" :src="item.imgUrl" alt="" />
            <p class="Icon-item">{{ item.title }}</p>
          </div>
        </div>
      </div>

      <div class="second">
        <h3 class="title-item">{{ titleItem[1] }}</h3>
        <div class="subtitle-item">
          动态展示物料出入库、解析、移库的过程，实现全流程自动化作业，清晰的数据化分析大屏能够
          <br />
          帮助管理人员进行相关计划、组织、控制和协调，提供高效的仓储服务
        </div>
        <!-- <img src="" alt="" class="mainSolutionBanner" /> -->
      </div>
      <div class="solutionBox" style="margin: 0 auto">
        <div class="solution-left">
          <div
            :class="[
              active === item.id
                ? 'solution-leftItem-acitve'
                : 'solution-leftItem',
            ]"
            @click="changeImg(item)"
            v-for="item in selectList"
            :key="item.id"
          >
            <div class="leftItem-tit">{{ item.tit }}</div>
            <div class="leftItem-toa">{{ item.con }}</div>
          </div>
        </div>
        <div class="solution-right">
          <img :src="activeImg" alt="" />
        </div>
      </div>
      <div class="solutionBox" style="margin: 0 auto; margin-top: 86px">
        <div class="leftBox"></div>
        <div class="rightBox">
          <div class="rightBox-tit">应用效果</div>
          <ul class="rightBox-list">
            <li>
              信息数字化，物料入库电子扫描，自动生成入库单，保存物料信息，移库、出库有据可查，信息及时更新。
            </li>
            <li>
              定制化区域划分，按种类规格定制化划分仓库区域，同种货物规范存放，提高找货效率。
            </li>
            <li>
              电子扫描获取物料信息，自动生成存放位置，自动调用调度系统帮运货物，无需人工操作，严格实行出入库规则，大大减少物料长时间滞留；
            </li>
            <li>
              定制化仓库大屏，仓库信息一览无余，移库出库一键操作，操作结果一键查看，大大减少员工工作量。
            </li>
          </ul>
        </div>
      </div>

      <div class="hiddenBox" style="visibility: hidden;">一飞立</div>
    </div>
  </div>
</template>

<script>
import Header from "@/baseui/header";
export default {
  name: "kwView",
  components: {
    Header,
  },
  data() {
    return {
      active: 1,
      activeImg: require("@/assets/kw-dp.png"),
      selectList: [
        {
          tit: "库位管理",
          con: "库位展示 库位信息 库位搜索",
          img: require("@/assets/kw-dp.png"),
          id: 1,
        },
        {
          tit: "订单管理",
          con: "出库单管理 入库单管理",
          img: require("@/assets/kw-dd.png"),
          id: 2,
        },
        {
          tit: "物料管理",
          con: "物料查询 物料预警",
          img: require("@/assets/kw-wl.png"),
          id: 3,
        },
        {
          tit: "库存管理",
          con: "库存清单 库存预警",
          img: require("@/assets/kw-kc.png"),
          id: 4,
        },
        {
          tit: "调度管理",
          con: "AGV调度 任务列队",
          img: require("@/assets/kw-rw.png"),
          id: 5,
        },
      ],
      topTitle: "库位管理解决方案",
      subtitle:
        "为企业库位提供数字化集中管理，满足供应链上下游的需求，提高企业效益",
      titleItem: ["企业库位管理痛点", "库位系统解决方案"],
      subtitleItem: [
        "物料复杂，库位庞大，数据无法实时集中展示",
        "动态展示物料出入库、解析、移库的过程，实现全流程自动化作业，清晰的数据化分析大屏能够帮助管理人员进行相关计划、组织、控制和协调，提供高效的仓储服务",
        "",
      ],
      mainIconArr: [
        {
          id: 1,
          imgUrl: require("./images/mainIcon-1.png"),
          title: "库位管理落后，无法实时展示库位信息",
        },
        {
          id: 2,
          imgUrl: require("./images/mainIcon-2.png"),
          title: "无法与AGV实时监控掌握作业动态",
        },
        {
          id: 3,
          imgUrl: require("./images/mainIcon-3.png"),
          title: "物料数据缺少管理，无有效沉淀",
        },
        {
          id: 4,
          imgUrl: require("./images/mainIcon-4.png"),
          title: "系统无移动端，操作效率低",
        },
      ],
    };
  },
  methods: {
    changeImg(val) {
      this.active = val.id;
      this.activeImg = val.img;
    },
  },
};
</script>

<style scoped>
.rightBox-list li {
  width: 580px;
  font-size: 18px;
  color: #5a6073;
  margin-bottom: 16px;
}
.rightBox-tit {
  padding-top: 27px;
  font-weight: 500;
  font-size: 32px;
  color: #191c27;
  height: 45px;
  line-height: 45px;
  padding-left: 18px;
  padding-bottom: 26px;
}
.rightBox {
  height: 385px;
  padding-left: 81px;
}
.leftBox {
  width: 452px;
  height: 385px;
  background: url("@/assets/kw-fa.png") no-repeat;
  background-size: 100% 100%;
}
.solutionBox {
  display: flex;
}
.solution-right {
  width: 865px;
  height: 650px;
  padding-left: 20px;
}
.solution-right img {
  width: 865px;
  height: 650px;
}
.solution-leftItem {
  width: 315px;
  height: 114px;
  color: #000;
  margin-top: 21px;
  background: #f8f9fc;
  border-radius: 1px;
}
.solution-leftItem-acitve {
  width: 315px;
  height: 114px;
  color: #ffffff;
  background: url("@/assets/kw-sel.png") no-repeat;
  background-size: 100% 100%;
}
.leftItem-tit {
  font-weight: 500;
  font-size: 24px;
  padding-left: 40px;
  padding-top: 26px;
}
.leftItem-toa {
  font-size: 18px;
  padding-left: 40px;
  padding-top: 5px;
}
.top {
  background-image: url(./images/kwbackgroundImg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-attachment: scroll; */
  /* background-size: cover; */
  background-color: #464646;
  padding: 154px 0 0 0;
  box-sizing: border-box;
  width: 100%;
  height: 525px;
}

.title {
  height: 62px;
  font-weight: 500;
  font-size: 44px;
  color: #ffffff;
  height: 62px;
  line-height: 62px;
}
.subtitle {
  font-size: 18px;
  color: #ffffff;
  margin-top: 21px;
}
.main {
  /* width: 100%; */
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 60px auto;
}
.first {
  margin: 40px 0;
}
.title-item {
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 36px;
  color: #191c27;
  text-align: center;
  line-height: 36px;
}
.subtitle-item {
  margin-bottom: 45px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #7e8395;
  line-height: 24px;
}
.IconBox {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-evenly;
}
.iconBox-item {
  text-align: center;
}
.IconItem {
  display: flex;
}
.Icon {
  width: 229px;
  height: 173px;
}
.Icon-item {
  width: 258px;
  height: 33px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 24px;
  color: rgba(25, 28, 39, 0.85);
  text-align: center;
}
.Icon-desc {
  width: 258px;
  height: 66px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 24px;
  color: #5a6073;
  text-align: center;
  margin-left: 67px;
}
.second {
  margin: 0 auto;
}
</style>
