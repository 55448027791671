<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    // if (navigator.userAgent.match(/(iPhone|Android|iPad)/i)) {
    //   // let href = "https://www.hao123.com/";
    //   // window.location.href = href
    //   // let width = document.documentElement.clientWidth;
    //   // let height = document.documentElement.clientHeight;
    //   // if (width < height) {
    //   //   alert("手机屏幕横过来嗷");
    //   //   this.loading = true;
    //   //   document.getElementById("loading").style.zIndex = 2021;
    //   //   window.addEventListener("resize", this.renderResize);
    //   // }
    // } else {
    // }
  },
};
</script>

<style >
@media screen and (max-width: 756px) {
}

body {
  margin: 0 !important;
}
.centerBox{
  width: 1200px;
  margin: 0 auto;
}

/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
} */
.containerBox {
  min-width: 1440px;
  width: 100%;
}
.flexBox {
  display: flex;
}
</style>
