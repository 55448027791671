<template>
  <div class="productView containerBox">
    <div class="top">
      <div style="width: 100%">
        <div class="top-title">
          飞立SCRM | 营销服务一体化 <br />
          私域流量运营平台
        </div>
        <div class="top-con">
          扎根装备制造与工程机械，专注于“以敏捷应用重构业务生态”
        </div>
        <div class="centerBox">
          <div class="useBtn" @click="useClick">免费试用</div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="first">
        <h3 class="description">
          以企业微信为底座，构建“一平台两中心”的SaaS服务
        </h3>
        <img src="./images/productBanner-2.png" alt="" class="banner-2" />
      </div>

      <div class="second">
        <h3 class="description">功能介绍</h3>
        <div class="secondItem">
          <img
            src="./images/functionBanner-1.png"
            alt=""
            class="funcBanner"
            style="width: 600px; height: 466.67px"
          />
          <div class="subItem-1">
            <img
              src="./images/functionIcon-1.png"
              alt=""
              class="funcBanner"
              style="width: 33px; height: 29px; margin-left: 15px"
            />
            <h4 class="title">智能线索分配管理</h4>
            <ul class="subtitle">
              <li>一键分配线索，灵活配置回收规则，预防线索资源浪费。</li>
              <li style="margin: 16px 0">
                线索回收提醒，线索跟进提醒，保证线索信息的完整性，
                准确性，提高线索转化效率。
              </li>
              <li>
                智能分析线索来源，甄选优质广告渠道，提高线索获取效率，
                降低广告成本。
              </li>
            </ul>
          </div>
        </div>

        <div class="secondItem">
          <div class="subItem-1">
            <img
              src="./images/functionIcon-2.png"
              alt=""
              class="funcBanner"
              style="width: 61px; height: 61px; margin-left: 20px"
            />
            <h4 style="margin-left: -15px" class="title">全流程商机管理</h4>
            <ul class="subtitle">
              <li>智能线索跟进提示，客户信息完善，线索商机转化。</li>
              <li style="margin: 16px 0">确认客户购买需求，挖掘客户购买潜力</li>
              <li>商机状态分析，根据商机状态，自动生成可视化销售漏斗。</li>
            </ul>
          </div>
          <img
            src="./images/functionBanner-2.png"
            alt=""
            class="funcBanner"
            style="width: 613px; height: 477px"
          />
        </div>

        <div class="secondItem">
          <img
            src="./images/functionBanner-3.png"
            alt=""
            class="funcBanner"
            style="width: 649px; height: 556px"
          />
          <div class="subItem-1">
            <img
              src="./images/functionIcon-3.png"
              alt=""
              class="funcBanner"
              style="width: 40px; height: 31px; margin-left: 15px"
            />
            <h4 class="title" style="margin-left: -33px">客户精运运营</h4>
            <ul class="subtitle">
              <li>客户按需分配，客户回收规则设置</li>
              <li style="margin: 16px 0">
                客户跟进，拜访等信息管理，实时获取客户最新信息
              </li>
              <li>节日客户关怀，生日礼品发放</li>
            </ul>
          </div>
        </div>

        <div class="secondItem">
          <div class="subItem-1">
            <img
              src="./images/functionIcon-4.png"
              alt=""
              class="funcBanner"
              style="width: 61px; height: 61px; margin-left: 19px"
            />
            <h4 class="title" style="margin-left: -30px">数据报表分析</h4>
            <ul class="subtitle">
              <li>客户新增，客户拜访，客户跟进统计</li>
              <li style="margin: 16px 0">
                线索获取统计，线索转换分析，优质线索分类
              </li>
              <li>根据商机状态生成销售漏斗，商机成交报表</li>
            </ul>
          </div>
          <img
            src="./images/functionBanner-4.png"
            alt=""
            class="funcBanner"
            style="width: 675px; height: 432.75px"
          />
        </div>
      </div>

      <div class="description">产品优势</div>
      <div class="thirdBox">
        <div v-for="(item, index) in advantageIcon" :key="index">
          <img
            class="funcBanner"
            :src="item.imgUrl"
            alt=""
            style="width: 324px; height: 275px"
          />
          <p class="advan-description">{{ item.desc }}</p>
        </div>
      </div>
    </div>
      <div class="hiddenBox" style="visibility: hidden;">一飞立</div>

  </div>
</template>

<script>
import Header from "@/baseui/header";
import Footer from "@/baseui/footer";
export default {
  name: "ProductView",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      waterfallArr: [
        {
          id: 1,
          img: require("./images/functionBanner-1.png"),
        },
        {
          id: 2,
          img: require("./images/functionBanner-2.png"),
        },
        {
          id: 3,
          img: require("./images/functionBanner-3.png"),
        },
        {
          id: 4,
          img: require("./images/functionBanner-4.png"),
        },
      ],
      advantageIcon: [
        {
          index: 1,
          imgUrl: require("./images/advantageIcon-1.png"),
          desc: "拥有全流程系统设计，着重于营销服务，致力于构建私域运营增长，促进企业发展",
        },
        {
          index: 2,
          imgUrl: require("./images/advantageIcon-2.png"),
          desc: "价格合适，提供前期沟通、调研诊断、策略定制、运营探索、sop运营、服务总结、后续服务等全流程实施方案",
        },
        {
          index: 3,
          imgUrl: require("./images/advantageIcon-3.png"),
          desc: "行业专注于装备制造业，解决方案更加专业",
        },
      ],
    };
  },
  methods: {
    useClick() {
      this.$parent.test();
    },
  },
};
</script>

<style scoped>

.productView {
  /* background: url('@/assets/page.png')no-repeat;
  background-size: 100% 100%; */
}
.top {
  background-image: url(./images/productBanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 154px 0 0 0;
  box-sizing: border-box;
  width: 100%;
  height: 525px;
}
.top-title {
  font-weight: 500;
  font-size: 44px;
  color: #191c27;
  width: 1200px;
  margin: 0 auto;
}
.top-con {
  font-size: 18px;
  color: #7e8395;
  width: 1200px;
  margin: 22px auto;
}
.useBtn {
  width: 168px;
  height: 56px;
  background: #0c5aff;
  border-radius: 8px;
  line-height: 56px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-top: 66px;
}
/* .top-box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 928px;
}
.el-button {
  width: 150px;
  height: 56px;
  background: #0c5aff;
  border-radius: 8px;
  margin-left: -300px;
  margin-top: 56px;
} */
.top-item {
  /* width: 488px; */
  height: 25px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #7e8395;
  margin-left: -300px;
  margin-top: 22px;
}
.thirdBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
.SCRM {
  /* width: 928px; */
  height: 124px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 44px;
  color: #191c27;
  margin-left: -300px;
  margin-top: 220px;
}
.main {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
}
.first {
  margin: 60px auto;
}
.description {
  font-weight: 500;
  font-size: 36px;
  color: #191c27;
  text-align: center;
  line-height: 36px;
  margin-top: 49px;
  margin-bottom: 68px;
}
.banner-2 {
  margin-top: 30px;
}
.second {
  margin: 12px auto;
  display: flex;
  flex-direction: column;
}

.secondItem {
  display: flex;
  margin-top: 12px;
}
.subItem-1 {
  width: 545px;
  height: 399px;
  margin-top: 76px;
  margin-left: 87px;
}
.title {
  width: 288px;
  height: 45px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 32px;
  color: #191c27;
  text-align: center;
}
.subtitle {
  width: 450px;
  height: 26px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #5a6073;
  line-height: 26px;
}
/* .third {
  margin: 0 auto;
} */
.adventageIconBox {
  display: flex;
  flex-direction: row;
}
.IconItem {
  display: flex;
}
.advan-description {
  width: 325px;
  height: 99px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  line-height: 26px;
  font-size: 18px;
  color: #5a6073;
  text-align: center;
}
</style>
