<template>
  <div class="SCRMView containerBox">
    <div class="top">
      <div class="centerBox">
        <div class="title">{{ topTitle }}</div>
        <div class="subtitle">
          {{ subtitle }}
        </div>
      </div>
    </div>
    <div class="main">
      <div class="first">
        <h3 class="title-item">{{ titleItem[0] }}</h3>
        <p class="subtitle-item">{{ subtitleItem[0] }}</p>
        <div class="IconBox">
          <div v-for="(item, id) in mainIconArr" :key="id" class="iconBox-item">
            <img class="Icon" :src="item.imgUrl" alt="" />
            <p class="Icon-item">{{ item.title }}</p>
            <p class="Icon-desc">{{ item.desc }}</p>
          </div>
        </div>
      </div>

      <div class="second">
        <h3 class="title-item">{{ titleItem[1] }}</h3>
        <img
          src="./images/mainSolutionBanner.png"
          alt=""
          class="mainSolutionBanner"
        />
      </div>

      <div class="third">
        <div class="thirdItem">
          <img src="./images/thirdIcon.png" alt="" class="thirdIcon" />
          <div class="thirdSubitem">
            <h4 class="thirdTitle">应用效果</h4>
            <ul
              class="thirdSubtitle"
              v-for="(item, id) in thirdSubtitleArr"
              :key="id"
            >
              <li>{{ item.desc }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/baseui/header";
import Footer from "@/baseui/footer";
export default {
  name: "SCRMView",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      topTitle: "SCRM解决方案-工程机械行业",
      subtitle: " 帮助工程机械企业加强数字化管理，构建企业私域增长新范式",
      titleItem: ["工程机械行业痛点", "企业精益运营管理解决方案"],
      subtitleItem: [
        "企业数字化转型解决方案提供商，与各企业开放合作，助力企业数字化转型",
      ],
      thirdSubtitleArr: [
        {
          id: 1,
          desc: "精细化客户信息、销售过程、销售行为管理，规范全员业务操作，效率大幅提升",
        },
        {
          id: 2,
          desc: "建立高粘度大客户管理管理流程，提高成单率",
        },
        {
          id: 3,
          desc: "打通各部门、各相关业务系统间的信息流，共享平台让业务流和数据流信息化",
        },
        {
          id: 4,
          desc: "各部门的实时数据解读、挖掘和分析，以图表、看板形式提供",
        },
      ],
      mainIconArr: [
        {
          id: 1,
          imgUrl: require("./images/mainIcon-1.png"),
          title: "拓客环节",
          desc: "客户引流问题，需要新的方式拓客",
        },
        {
          id: 2,
          imgUrl: require("./images/mainIcon-2.png"),
          title: "业务管理",
          desc: "人员能效难以统计，销售行为难以管理",
        },
        {
          id: 3,
          imgUrl: require("./images/mainIcon-3.png"),
          title: "销售转化",
          desc: "客户意向难以洞察，转换率低",
        },
        {
          id: 4,
          imgUrl: require("./images/mainIcon-4.png"),
          title: "客户留存",
          desc: "员工离职客户流失",
        },
      ],
    };
  },
};
</script>

<style scoped>
.top {
  background-image: url(./images/SCRMBanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* background-attachment: scroll; */
  /* background-size: cover; */
  background-color: #464646;
  width: 100%;
  height: 525px;
  padding: 154px 0 0 0;
  box-sizing: border-box;
}
.top-box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 928px;
}
.title {
  font-weight: 500;
  font-size: 44px;
  color: #ffffff;
  height: 62px;
  line-height: 62px;
}
.subtitle {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: #ffffff;
  margin-top: 21px;
}
.main {
  /* width: 100%; */
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
.first {
  margin: 40px auto;
}
.title-item {
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 36px;
  color: #191c27;
  text-align: center;
  line-height: 36px;
}
.subtitle-item {
  margin-bottom: 45px;
  text-align: center;
  height: 18px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #7e8395;
  line-height: 18px;
}
.IconBox {
  display: flex;
  flex-direction: row;
}
.IconItem {
  display: flex;
}
.Icon {
  width: 189.17px;
  height: 148px;
  /* margin: 0 100px; */
}
.iconBox-item {
  text-align: center;
  width: 258px;
  margin-right: 56px;
}
.Icon-item {
  /* width: 180px; */
  height: 33px;
  font-size: 24px;
  color: rgba(25, 28, 39, 0.85);
  text-align: center;
}
.Icon-desc {
  width: 258px;
  height: 66px;
  font-weight: 400;
  font-size: 24px;
  color: #5a6073;
  text-align: center;
}
.second {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mainSolutionBanner {
  margin-top: 35px;
  width: 100%;
  height: 466px;
}
.third {
  margin: 80px auto;
  display: flex;
}
.thirdIcon {
  width: 470px;
  height: 400px;
}
.thirdItem {
  display: flex;
  margin-top: 12px;
}
.thirdSubitem {
  margin-left: 130px;
}
.thirdTitle {
  margin-left: 20px;
  height: 45px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 32px;
  color: #191c27;
}
.thirdSubtitle {
  width: 540px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #5a6073;
  line-height: 26px;
}
</style>
