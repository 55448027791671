<template>
  <div class="footer">
    <div class="inside-box">
      <div class="above">
        <div class="footer-left">
          <img src="./images/footer-man.png" alt="" class="footer-man" />
        </div>
        <div class="footer-right">
          <!-- <div class="title">免费咨询申请使用</div> -->
          <el-form label-width="60px" :model="form">
            <el-form-item>
              <input
                v-model="form.name"
                class="name-input"
                placeholder="请输入您的姓名"
                style="margin-top: 3px"
              />
              <div class="get-phonenum">
                <input
                  v-model="form.phone"
                  class="contact-input"
                  placeholder="请输入联系电话"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                />
              </div>
              <div class="get-phonenum">
                <input
                  v-model="form.companyName"
                  class="name-input"
                  placeholder="请输入贵公司名称"
                />
              </div>
              <el-button type="primary" class="handleinfo" @click="submitClick"
                >提交信息</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="inside-box2">
      <div class="below">
        <div class="upper">
          <div class="companyinfo">
            <img
              src="./images/logo-white.png"
              alt=""
              style="margin-top: 20px;margin-left:-10px;"
              class="companylogo"
            />
            <div>
              <div class="compainfo">
                咨询邮箱：
                <div class="detailInfo">service@yeaflytech.com</div>
              </div>
              <div class="compainfo">
                公司地址：
                <div class="detailInfo-2">
                  深圳市南山区西丽街道松坪山社区宝深路109号
                  <br />
                  国民技术大厦1609
                  <br />
                  湖南省长沙市岳麓区麓谷企业广场C3栋501室
                </div>
              </div>
            </div>
          </div>
          <div class="footerlist">
            <div class="footerItem">
              <h4>产品服务</h4>
              <ul class="footerItemCon">
                <li>飞立SCRM</li>
                <li>数字化解决方案</li>
                <li>IT服务与咨询</li>
              </ul>
            </div>
            <div class="footerItem">
              <h4>解决方案</h4>
              <ul class="footerItemCon">
                <li @click="goRouter('sc')">SCRM系统</li>
                <li @click="goRouter('kw')">库位系统</li>
              </ul>
            </div>
            <div class="footerItem">
              <h4>资源中心</h4>
              <ul class="footerItemCon">
                <li>CRM使用手册</li>
                <li>展销系统使用手册</li>
              </ul>
            </div>
            <div class="footerItem">
              <h4>关于我们</h4>
              <ul class="footerItemCon">
                <li @click="goRouter('gs')">公司介绍</li>
                <li @click="goRouter('jr')">加入我们</li>
              </ul>
            </div>
            <div class="footerItem">
              <h4>关注我们</h4>
              <img src="./images/QRCode.jpg" alt="" class="QRCode" />
            </div>
          </div>
        </div>
        <div class="lower">
          <div class="information">
            <!-- <div style="margin-bottom: 12px">
              开发者：深圳一飞立科技有限公司 ｜ 版本号：v1.0.0
              ｜更新时间：2022-04-08
            </div> -->

            <div style="margin-bottom: 12px">
              Copyright(c)2021, 深圳一飞立科技有限公司版权所有 &nbsp;
              <a href="https://beian.miit.gov.cn/" target="_blank"
                >粤ICP备2021115381号-2</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
  components: {},
  data() {
    return {
      name: "",
      tel: "",
      company: "",
      form: {},
    };
  },
  methods: {
    goRouter(type) {
      switch (type) {
        case "sc":
          this.$router.push("/SCRM");
          break;
        case "kw":
          this.$router.push("/kwsolution");
          break;
        case "gs":
          this.$router.push("/aboutus");
          break;
        case "jr":
          this.$router.push("/joinus");
          break;
      }
      this.toTop();
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    submitClick() {
      if (!this.form.name) {
        this.$message.warning("请填写您的姓名");
        return;
      } else if (!this.form.phone) {
        this.$message.warning("请填写您的手机号");
        return;
      } else if (!this.form.companyName) {
        this.$message.warning("请填写贵公司名称");
        return;
      } else {
        let axios = require("axios");
        axios({
          method: "post",
          url: "https://www.yeaflytech.com/api/homeCustomers/save",
          params: this.form,
        }).then((res) => {
          this.$message.success("已提交，请您等候回复");
        });
      }

      // this.$axios({
      //   methods: "post",
      //   url: "/homeCustomers/save",
      //   params: this.form,
      // });
    },
  },
};
</script>

<style scoped>
.companylogo {
  width: 172px;
  height: 70px;
}
.footerItem li {
  cursor: pointer;
  margin-top: 10px;
}
a {
  text-decoration: none;
}
.footer {
  /* 加载背景图 */
  background-image: url(./images/footerbanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  /* background-size: cover; */
  background-color: #464646;
  width: 100%;
  min-width: 1440px;
  min-height: 479px;
  display: flex;
  flex-direction: column;
}
.inside-box {
  margin: 0 auto;
}
.above {
  display: flex;
  width: 1200px;
  justify-content: space-between;
}

.footer-right {
  /* margin-left: 320px; */
  margin-top: 30px;
}
.footerbanner {
  width: 100%;
  height: 100%;
  z-index: -10;
}
.footer-man {
  margin-top: 37px;
  width: 536px;
  height: 188px;
}
.title {
  /* width: 192px; */
  height: 33px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  /* display: inline-block; */
  margin-top: 10px;
  /* margin-left: 155px; */
}

.get-btn {
  width: 149px;
  height: 57px;
  background: rgba(148, 151, 161, 0.15);
  border: 1px solid rgba(249, 250, 255, 0.3);
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 8px;
}
.yzm {
  font-family: PingFangSC-Medium;
  color: #ffffff;
}
.name-input {
  width: 453px;
  height: 40px;
  background: rgba(148, 151, 161, 0.15);
  border: 1px solid rgba(249, 250, 255, 0.3);
  border-radius: 5px;
  margin-top: 10px;
  color: #ffffff;
  box-sizing: border-box;
  padding-left: 10px;
}
.contact-input {
  box-sizing: border-box;
  padding-left: 10px;
  width: 453px;
  height: 40px;
  background: rgba(148, 151, 161, 0.15);
  border: 1px solid rgba(249, 250, 255, 0.3);
  border-radius: 5px;
  margin-top: 10px;
  color: #ffffff;
}
.code-input {
  width: 235px;
  height: 54px;
  background: rgba(148, 151, 161, 0.15);
  border: 1px solid rgba(249, 250, 255, 0.3);
  border-radius: 5px;
  margin-top: 10px;
  /* margin-left: 338px; */
}
.handleinfo {
  margin-top: 12px;
  width: 453px;
  height: 40px;
  border-radius: 8px;
  background: #0c5aff;
}
.inside-box2 {
  background: rgba(19, 19, 31, 0.3);
}

.below {
  width: 100%;
  /* height: 401px; */
  /* background: rgba(19, 19, 31, 0.3); */
  /* margin: 0 auto; */
  display: flex;
  flex-direction: column;
}
.upper {
  display: flex;
  margin: 0 auto;
  width: 1200px;
  justify-content: space-between;
}
.companyinfo {
  /* margin-right: 300px; */
}
.compainfo {
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 1.14px;
  width: 460px;
  /* margin-left: 132px; */
  margin-top: 10px;
}

.detailInfo {
  width: 249px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #a8aabe;
  letter-spacing: 1.14px;
  display: inline-block;
  margin-left: -4px;
}
.detailInfo-2 {
  /* width: 249px; */
  position: relative;
  left: 88px;
  bottom: 20px;

  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #a8aabe;
  letter-spacing: 1.14px;
}
.footerlist {
  display: flex;
  justify-content: space-between;
}

ul,
li {
  list-style: none;
  padding-left: 0 !important;
  text-align: center;
}
li {
  width: 138px;
  height: 22px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 16px;
  color: #a8aabe;
  letter-spacing: 1.14px;
}
h4 {
  /* margin-left: 37px; */
  /* width: 86px; */
  height: 28px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 1.43px;
  text-align: center;
}
.QRCode {
  margin-left: 35px;
  width: 118px;
  height: 118px;
}
.lower {
  text-align: center;
  margin-top: 27px;
}
.information {
  display: flex;
  flex-direction: column;
  /* width: 1200px;
  height: 100px; */
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  /* text-align: center; */
  color: #acacae;
}
.information a {
  color: #acacae;
}
</style>
