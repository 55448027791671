<template>
  <div class="containerBox">
    <div class="headBox">
      <div class="centerBox">
        <div class="head-tit">加入我们 共创辉煌</div>
        <div class="head-con">欢迎一切有志之士来投递！</div>
        <div class="useBtn" @click="zxClick">立即加入</div>
      </div>
    </div>
    <div class="conBox">
      <!-- <div class="conHead">
        <div :class="acitveHead === 1 ? 'conHead-active' : 'conHead-item'">
          开发
        </div>
        <div class="conHead-item">管理</div>
        <div class="conHead-item">运维</div>
      </div> -->
      <div class="conBot flexBox">
        <div class="conBot-left">
          <div :class="acitveHead === 1 ? 'conHead-active' : 'conHead-item'">
            开发
          </div>
          <div
            class="select"
            :class="[active === item.id ? 'selectActive' : 'selectItem']"
            v-for="(item, index) in positon"
            :key="index"
            @click="selectClick(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="conBot-right">
          <img src="@/assets/join-zp.png" alt="" class="zpImg" />
          <div class="conBot-zpCon" v-if="activeInfo">
            <!-- <div class="zpCon-num">招聘数量({{ activeInfo.num }}名)</div> -->
            <div class="zpCon-zz">岗位职责</div>
            <div
              class="zpCon-con"
              v-for="child1 in activeInfo.one"
              :key="child1.id"
            >
              {{ child1.text }}
            </div>
            <div class="zpCon-zz">岗位职责</div>
            <div
              class="zpCon-con"
              v-for="child in activeInfo.two"
              :key="child.id"
            >
              {{ child.text }}
            </div>
            <div class="joinBox">
              <div class="showBox">
                欢迎有志之士投递简历，简历投递邮箱：service@yeaflytech.com
                您也可以点击立即加入沟通咨询
              </div>
              <div class="joinBtn" @click="comClick">立即加入</div>
            </div>
          </div>
          <div class="conBot-zpCon" v-else>
            <img src="@/assets/join-zw.png" alt="" class="zwImg" />
            <div class="zwText">抱歉！目前暂无该岗位需求，敬请期待！</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinusView",
  data() {
    return {
      acitveHead: 1,
      positon: [
        {
          name: "01  | 后端开发工程师",
          id: "01",
          info: {
            num: 4,
            one: [
              {
                text: "1.遵循公司相关技术标准、规范，高质量完成设计、开发任务;",
                id: "11",
              },
              {
                text: "2.参与系统需求分析和详细设计工作，并按照设计的功能，实现模块的代码编写和维护;",
                id: "12",
              },
              {
                text: "3.协助完成项目测试、系统上线工作，对项目实施提供支持;",
                id: "13",
              },
            ],
            two: [
              {
                text: "1.计算机或相关专业本科及以上学历,3年以上JAVA 实际开发经验;",
                id: 1,
              },
              {
                text: "2.熟悉Java，熟练使用J2EE编程，了解常用框架原理（Spring MVC、Mybatis、SpringBoot、Dubbo或Spring Cloud等）;",
                id: 2,
              },
              {
                text: "3.掌握 SQL、Redis、队列（RabbitMQ、Kafka等）、Nginx等技术框架;",
                id: 3,
              },
              {
                text: "4.熟练使用Linux常用命令，编写Shell脚本,熟悉Tomcat等容器配置；给一台空服务器，会搭建各种中间件更好;",
                id: 4,
              },
              {
                text: "5.熟练使用 Maven、Git和 Jenkins 等工具;",
                id: 5,
              },
              {
                text: "6.具备数据库应用系统规划及设计能力，至少使用过Oracle、MySQL等一种数据库，参与过大型项目大批量的数据处理，有数据库调优经验;",
                id: 6,
              },
              {
                text: "7.具备数据库应用系统规划及设计能力，至少使用过Oracle、MySQL等一种数据库，参与过大型项目大批量的数据处理，有数据库调优经验;",
                id: 7,
              },
              {
                text: "8.条件优秀者可放宽至大专学历，高级经理待遇面议;",
                id: 8,
              },
            ],
          },
        },
        {
          name: "02  | 前端开发工程师",
          id: "02",
          info: {
            num: 3,
            one: [
              {
                text: "1.负责相关产品Web/H5/App的前端开发，对前端团队产出的质量和效率负责;",
                id: "11",
              },
              {
                text: "2.负责核心系统的设计和编码;",
                id: "12",
              },
              {
                text: "3.负责数据平台的可视化，设计或选用前端基础组件，搭建大型网页应用;",
                id: "13",
              },
            ],
            two: [
              {
                text: "1.大学本科及以上学历，3年以上前端研发经验，积累深厚;",
                id: 1,
              },
              {
                text: "2.热爱计算机科学和互联网技术，对各种Web前端技术有深刻理解;",
                id: 2,
              },
              {
                text: "3.关注Web发展，对新技术充满激情，期待或者已经开发出优秀的产品;",
                id: 3,
              },
              {
                text: "4.有Ajax相关的实践经验，对React.js，VUE.js，Angular.js等MVVM框架能熟练运用至少一种，且了解其基本原理;",
                id: 4,
              },
              {
                text: "5.对可用性、可访问性等相关知识有实际的了解和实践经验;",
                id: 5,
              },
              {
                text: "6.熟悉服务器端Web应用结构，有服务器端脚本语言经验更佳;",
                id: 6,
              },
              {
                text: "7.团队精神，性格乐观，能积极面对压力;",
                id: 7,
              },
              {
                text: "8.条件优秀者可放宽至大专学历，高级经理待遇面议;",
                id: 8,
              },
              {
                text: "9.有企业微信、CRM app产品开发经验优先 ;",
                id: 9,
              },
            ],
          },
        },
        {
          name: "03  | 软件测试工程师",
          id: "03",
          info: {
            num: 1,
            one: [
              {
                text: "1.参与需求设计评审，从测试角度给予评审意见，帮助提升产品功能性、使用性及产品质量;",
                id: "11",
              },
              {
                text: "2.负责编写软件测试计划，测试详细用例等测试文档;",
                id: "12",
              },
              {
                text: "3.参与测试用例评审，根据测试用例，执行模块测试、集成测试、系统测试;",
                id: "13",
              },
              {
                text: "4.与项目相关人员就项目进度和问题进行沟通，确保测试计划的顺利执行;",
                id: "14",
              },
              {
                text: "5.搭建、配置、管理测试平台和工具;",
                id: "15",
              },
              {
                text: "6.负责系统发布类文档的编写，如操作手册、测试报告等;",
                id: "16",
              },
            ],
            two: [
              {
                text: "1.具备独立分析需求文档并编写测试用例的能力;",
                id: 1,
              },
              {
                text: "2.掌握软件测试方法/技术，熟悉掌握主流的功能及性能测试工具，如selenium、jester、monkeyrunner、LoadRunner等;",
                id: 2,
              },
              {
                text: "3.熟悉Tomcat、Weblogic、Websphere等其中至少一种中间件;",
                id: 3,
              },
              {
                text: "4.熟悉Oracle等常用数据库的基本原理和结构，能编写基本SQL语句辅助测试;",
                id: 4,
              },
              {
                text: "5.掌握基本的软件测试理论,熟悉软件测试流程和规范，掌握多种软件测试手段;",
                id: 5,
              },
              {
                text: "6.具有互联网SaaS产品测试经验优先;",
                id: 6,
              },
            ],
          },
        },
        {
          name: "04  | 产品经理",
          id: "04",
          info: {
            num: 1,
            one: [
              {
                text: "1.负责项目前期阶段的需求分析、方案交流、方案设计、概念验证等工作;",
                id: "11",
              },
              {
                text: "2.负责业务梳理、需求调研和分析，完成产品规划设计、工作流程图和产品需求文档;",
                id: "12",
              },
              {
                text: "3.负责产品质量控制、承担部门协调、研发资源整合等任务，按期保质完成项目上线;",
                id: "13",
              },
              {
                text: "4.监控产品运营数据，分析数据，对产品提供针对性改进及支持日常的产品运营工作;",
                id: "14",
              },
            ],
            two: [
              {
                text: "1.全日制统招本科学历及以上;",
                id: 1,
              },
              {
                text: "2.在企业环境中实施数字化解决方案的经验，并熟练掌握互联网产品设计;",
                id: 2,
              },
              {
                text: "3.了解企业信息系统（ERP/CRM/SOA/SCRM），并参与过解决方案设计与实施;",
                id: 3,
              },
              {
                text: "4.对2个以上业务领域/行业的价值链和业务能力有深刻理解;",
                id: 4,
              },
              {
                text: "5.有传统企业数字化转型经验，主导过创新产品0-1产品落地者优先;",
                id: 5,
              },
              {
                text: "6.条件优秀者可放宽至大专学历，高级经理待遇面议;",
                id: 6,
              },
            ],
          },
        },
      ],
      active: "01",
      activeInfo: {},
    };
  },
  created() {
    this.activeInfo = this.positon[0].info;
  },
  methods: {
    selectClick(val) {
      this.active = val.id;
      this.activeInfo = val.info || "";
    },
    zxClick() {
      window.open(
        "https://www.zhipin.com/gongsi/4d493428c2867d211nBz09u5FFI~.html?ka=search_rcmd_company_4d493428c2867d211nBz09u5FFI~_custompage"
      );
    },
    comClick() {
      switch (this.active) {
        case "01":
          window.open(
            "https://www.zhipin.com/job_detail/b39f5e387e35b0f01XZ52d-_GFBR.html?ka=search_list_jname_2_blank&lid=2X6oZoRAkMb.search.2&securityId=r9j2KT2bkrYOp-31v0xfv8s4chmYkJPvFLjpAipFA_1It8Yb_c9xV1bYizHR4DO3LJIe6ayvHQjRG_13LPg7XTGvLaNwArbQ5ryU5fCX_xWjRXIWbFTrpCr4MtE9HYHmnJUKogUBVIuA-j4wOq3f_q1J"
          );
          break;
        case "02":
          window.open(
            "https://www.zhipin.com/job_detail/dc95acc926ccba001XR52dq5GVJR.html?ka=search_list_jname_5_blank&lid=2XlCagqnZyC.search.5&securityId=Xstd3MeLKCyL0-k1DqiYZmhybz6bgLrIQbBB_ltJn-NQKIDB8xok54zw7bizn_DzMNtBwJNhXSt4ANoNNCqzlALAANi1YUCxO5dJzpANnvboafnubDU%7E"
          );
          break;
        case "03":
          this.zxClick();
          break;
        case "04":
          window.open(
            "https://www.zhipin.com/job_detail/6f380d8386fcbf501XZ70tm7E1BT.html?ka=search_list_jname_1_blank&lid=2X6oZoRAkMb.search.1&securityId=LVZr1qdnqCZSM--11Iu1-Y5FtnwuoSWqNVh3SNZglRo9HqZko8I7uZAEf4FAfLXeh5qPu-dVyFVlmuSjpOmcBN2ATOu8q3_emGey-4o3aUMa4EJJoX-c0G0jk2EH_EGPgd3RNvw0s1H2cRqE0FYFLFuW"
          );
          break;
      }
    },
  },
};
</script>

<style scoped>
.joinBox {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 100%;
  align-items: center;
  padding-right: 100px;
  box-sizing: border-box;
}
.showBox {
  width: 466px;
  font-size: 16px;
  color: #1c202e;
}
.joinBtn {
  width: 120px;
  height: 40px;
  background: #0c5aff;
  box-shadow: 0 6px 16px 0 rgba(229, 232, 255, 0.5);
  border-radius: 8px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  /* position: absolute;
  right: 25px;
  bottom: 10px; */
}
.zwText {
  height: 33px;
  line-height: 33px;
  text-align: center;
  font-size: 24px;
  color: #1c202e;
}
.zwImg {
  width: 209px;
  height: 201px;
  margin: 106px 0 89px 285px;
}
.zpCon-con {
  font-size: 18px;
  color: #808082;
  line-height: 28px;
}
.zpCon-zz {
  color: #1c202e;
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 10px;
}
.zpCon-num {
  font-size: 20px;
  color: #1c202e;
  height: 28px;
  line-height: 28px;
}
.conBot-zpCon {
  width: 773px;
  height: 606px;
  background-image: linear-gradient(139deg, #ffffff 0%, #f3f8ff 88%);
  border-radius: 0 0 8px 8px;
  padding: 20px 20px 0 40px;
  box-sizing: border-box;
  margin-left: 43px;
  position: relative;
}
.zpImg {
  width: 773px;
  height: 129px;
  margin: 0px 0 0 43px;
}
.selectItem {
  background: #f8f9fc;
  border-radius: 1px;
  width: 346px;
  height: 155px;
  font-weight: 400;
  font-size: 18px;
  color: #2b1a1a;
  cursor: pointer;
}
.selectActive {
  background: url("@/assets/join-sel.png") no-repeat;
  background-size: 100% 100%;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  width: 346px;
  height: 155px;
}
.select {
  margin-top: 20px;
  padding: 40px 0 0 66px;
  box-sizing: border-box;
}
.select:first {
  margin-top: 0px !important;
}
.conHead {
  display: flex;
  justify-content: space-between;
}
.conHead-item {
  width: 345.72px;
  height: 84px;
  background: #f8f9fc;
  border-radius: 1px;
  line-height: 84px;
  font-weight: 400;
  font-size: 24px;
  color: #2b1a1a;
  text-align: center;
  cursor: pointer;
}
.conHead-active {
  width: 345.72px;
  height: 84px;
  background: #2168fe;
  border-radius: 1px;
  line-height: 84px;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
}
.conBox {
  padding: 33px 0px 85px 0px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1200px;
}
.conBot{
  justify-content: space-between;
}
.headBox {
  width: 100%;
  height: 525px;
  background: url("@/assets/join-hd.png") no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  padding: 154px 0 0 0;
  box-sizing: border-box;
}
.head-tit {
  height: 62px;
  font-weight: 500;
  font-size: 44px;
  color: #191c27;
  line-height: 62px;
}
.head-con {
  width: 466px;
  height: 25px;
  font-size: 18px;
  color: #7e8395;
  line-height: 25px;
  margin-bottom: 121px;
  margin-top: 21px;
}
.useBtn {
  width: 168px;
  height: 56px;
  background: #0c5aff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 56px;
  cursor: pointer;
}
</style>
