<template>
  <div class="home">
    <div class="insidebox">
      <div class="left">
        <router-link to="/home">
          <img src="./images/newlogo.png" class="companylogo" />
        </router-link>
        <!-- <img src="./images/welogo.png" class="welogo" /> -->
      </div>
      <div class="right">
        <div
          :class="[acitveIdx === item.id ? 'active-item' : 'head-item']"
          v-for="item in headList"
          :key="item.id"
          @click="handleChange(item)"
          @mouseenter="handleEnter(item)"
          @mouseleave="handleLeave(item)"
        >
          {{ item.name }}
          <div class="childBox" v-if="item.children && showChild">
            <div
              :class="[
                activeChild === child.id ? 'active-child' : 'child-item',
              ]"
              v-for="child in item.children"
              :key="child.id"
              @click="childClick(child)"
            >
              {{ child.name }}
            </div>
          </div>
        </div>
        <div class="useBtn" @click="handleUse">免费试用</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderView",
  components: {},
  data() {
    return {
      headList: [
        {
          name: "首页",
          id: 1,
          path: "/home",
        },
        {
          name: "产品",
          id: 2,
          path: "/product",
        },
        {
          name: "解决方案",
          id: 3,
          children: [
            {
              name: "SCRM解决方案",
              id: 1,
              path: "/SCRM",
            },
            {
              name: "库位解决方案",
              id: 2,
              path: "/kwsolution",
            },
          ],
        },
        {
          name: "关于我们",
          id: 4,
          path: "/aboutus",
        },
        {
          name: "加入我们",
          id: 5,
          path: "/joinus",
        },
      ],
      acitveIdx: 1,
      showChild: false,
      activeChild: "",
    };
  },
  watch: {
    $route(val) {
      switch (val.fullPath) {
        case "/home":
          this.acitveIdx = 1;
          break;
        case "/product":
          this.acitveIdx = 2;
          break;
        case "/SCRM":
          this.acitveIdx = 3;
          this.activeChild = 1;
          break;
        case "/kwsolution":
          this.acitveIdx = 3;
          this.activeChild = 2;
          break;
        case "/aboutus":
          this.acitveIdx = 4;
          break;
        case "/joinus":
          this.acitveIdx = 5;
          break;
      }
    },
  },
  methods: {
    handleChange(val) {
      if (val.children) {
        // this.acitveIdx = val.id;
      } else {
        this.showChild = false;
        this.acitveIdx = val.id;
        this.$router.push(val.path);
        this.activeChild = "";
        this.toTop();
      }
    },
    // 鼠标进入事件
    handleEnter(val) {
      if (val.id === 3) {
        this.showChild = true;
      }
    },
    // 鼠标移出事件
    handleLeave(val) {
      this.showChild = false;
    },
    // 子级点击事件
    childClick(val) {
      this.activeChild = val.id;
      this.acitveIdx = 3;
      this.$router.push(val.path);
    },
    // 免费试用按钮方法
    handleUse() {
      this.$parent.test();
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.home {
  position: absolute;
  width: 100%;
  min-width: 1440px;
  height: 64px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
}
.insidebox {
  display: flex;
  justify-content: space-between;
  height: 64px;
  margin: 0 auto;
  width: 1200px;
}
.left {
  width: 37%;
}
.head-item {
  width: 112px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.active-item {
  width: 112px;
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #0c5aff;
}
.childBox {
  width: 180px;
  height: 106px;
  background: #ffffff;
  text-align: left;
  padding-left: 16px;
  box-sizing: border-box;
}
.child-item {
  height: 53px;
  line-height: 53px;
  color: #5b5c61;
  font-size: 14px;
}
.active-child {
  height: 53px;
  line-height: 53px;
  font-weight: 600;
  font-size: 14px;
  color: #191c27;
}
.childBox :first-child {
  border-bottom: 1px solid #f5f5f5;
}
.useBtn {
  width: 88px;
  height: 32px;
  color: #fff;
  background: #0c5aff;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  margin-left: 10px;
}
.companylogo {
  position: absolute;
  width: 143px;
  height: 58px;
  /* margin-top: 12.22px; */
  object-fit: cover;
}
.welogo {
  /* position: absolute; */
  width: 184px;
  height: 32px;
  margin-left: 162px;
  margin-top: 16px;
}

.right {
  display: flex;
  align-items: center;
}
</style>
