<template>
  <div class="containerBox">
    <div class="main">
      <Carousel></Carousel>
      <div class="solution">
        <div class="solutionitem">数字化解决方案</div>
        <div class="solutionitem-2">
          数字化企业转型解决方案提供商，与各企业开放合作，助力企业数字化转型
        </div>
        <img src="./images/main-solution.png" alt="" />
        <div class="consult">
          <div class="consultbutton">
            <el-button type="primary" @click="zxClick">立即咨询</el-button>
          </div>
        </div>
      </div>
      <div class="serviceandconsult">
        <div class="solutionitem">IT服务与咨询</div>
        <img src="./images/service-icon.png" alt="" class="consulticon" />
        <div class="consult">
          <div class="consultbutton">
            <el-button type="primary" @click="zxClick">立即咨询</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/baseui/header";
import Footer from "@/baseui/footer";
// import Carousel from "@/components/carousel";

import Carousel from "@/components/carou";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
    Carousel,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    zxClick() {
      this.$parent.test();
    },
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1248px) {
  .main {
    width: auto !important;
    margin: 0 auto;
  }
}
.dh-num {
  margin-top: 8px;
  height: 16px;
  font-size: 24px;
  color: #333333;
  line-height: 16px;
}
.dhsj {
  height: 16px;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  line-height: 16px;
}
.dhzx {
  height: 32px;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 32px;
  padding-right: 5px;
}
.dhBox {
  width: 240px;
  height: 96px;
  background: #f5f6f9;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 2px 0 rgba(148, 148, 148, 0.5);
  padding: 16px 0 0 13px;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  bottom: -36px;
  right: 70px;
}
.codeImg {
  width: 240px;
  height: 297px;
  position: absolute;
  top: 0;
  right: 70px;
}
.zxImg :first {
  margin-top: 0px;
}
.zxImg {
  width: 60px;
  height: 60px;
  display: block;
  margin-top: 10px;
}
.fixedBox {
  position: fixed;
  top: 183px;
  right: 119px;
  z-index: 3;
}

.solution {
  margin: 80px auto;
  text-align: center;
}
.solutionitem {
  height: 36px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 36px;
  color: #191c27;
  line-height: 36px;
}
.solutionitem-2 {
  height: 18px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 18px;
  color: #7e8395;
  line-height: 18px;
  margin-top: 40px;
}
.el-button {
  width: 200px;
  height: 56px;
  background: #0c5aff;
  border-radius: 8px;
}
.serviceandconsult {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px auto;
  /* text-align: center; */
}
.consulticon {
  margin-top: 37px;
  width: 1200px;
  margin-bottom: 78px;
}
</style>
