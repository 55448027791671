<template>
  <div class="box">
    <div class="gs-rh flexBox">
      <div
        :class="[acitveRh === 1 ? 'rh-active' : 'rh-item']"
        style="margin-right: 20px"
        @click="addrClick(1)"
      >
        深圳总部
      </div>
      <div
        :class="[acitveRh === 2 ? 'rh-active' : 'rh-item']"
        @click="addrClick(2)"
      >
        湖南分公司
      </div>
    </div>
    <div id="container" class="container"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["ware"],
  data() {
    return {
      addr: "",
      city: "长沙市",
      addressList: "",
      lat: 22.514427,
      lng: 113.902838,
      map: "",
      acitveRh: 1,
    };
  },
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    addrClick(num) {
      switch (num) {
        case 1:
          this.lat = 22.514427;
          this.lng = 113.902838;
          this.getLatIng();
          break;
        case 2:
          this.lat = 28.207717;
          this.lng = 112.88633;
          this.getLatIng();
          break;
      }
      this.acitveRh = num;
    },
    initMap() {
      var center = new TMap.LatLng(this.lat, this.lng);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new TMap.Map(document.getElementById("container"), {
        center: center, //设置地图中心点坐标
        zoom: 17.2, //设置地图缩放级别
        pitch: 43.5, //设置俯仰角
        rotation: 0, //设置地图旋转角度
      });
      //定义地图中心点坐标
      this.MultiMarker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          // 点标记样式
          marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", // 标记路径
          }),
        },
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: center, //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
    getLatIng(e) {
      //   this.lat = e.lat;
      //   this.lng = e.lng;
      let self = this;
      //更新地图中心位置
      self.map.setCenter(new TMap.LatLng(this.lat, this.lng));
      this.MultiMarker.updateGeometries([
        {
          id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          styleId: "myStyle", //指定样式id
          position: new TMap.LatLng(this.lat, this.lng), //点标记坐标位置
        },
      ]);
    },
  },
};
</script>

<style scoped>
#container {
  width: 576px;
  height: 320px;
  /* margin-left: 121px; */
  margin-top: 21px;
}
.rh-active {
  width: 108px;
  height: 32px;
  background: #dae6ff;
  border-radius: 4px;
  font-size: 12px;
  color: #0c5aff;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
}
.rh-item {
  width: 108px;
  height: 32px;
  background: #eaeffa;
  border-radius: 4px;
  font-size: 12px;
  color: #969eb4;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
</style>
