import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
Vue.prototype.$axios = axios;
axios.defaults.baseURL = 'https://www.yeaflytech.com/api'
axios.interceptors.request.use(
  config => {
    config.headers["Content-type"] = "application/x-www-form-urlencoded";
    return config;
  },
  error => {
    return Promise.reject(error)
  }
)





import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
