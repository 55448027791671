<template>
  <div>
    <div class="showImg" @mouseenter="handleEnter" @mouseleave="handleLeave">
      <img
        @mouseover="changeInterval(true)"
        @mouseleave="changeInterval(false)"
        v-for="item in imgArr"
        :key="item.id"
        :src="item.url"
        v-show="item.id === currentIndex"
      />
      <div class="caro-text" v-show="currentIndex === 0">
        <div class="caro-tit">
          飞立SCRM | 营销服务一体化 <br />
          私域流量运营平台
        </div>
        <div class="caro-con">
          扎根装备制造与工程机械，专注于“以敏捷应用重构业务生态”
        </div>
      </div>
      <div class="caro-text" v-show="currentIndex === 1">
        <div class="caro-tit">一站式线索营销管理</div>
        <div class="caro-con">
          全面推广营销，线上线下活动举行，获取更多公海客户
        </div>
      </div>
      <div class="caro-text" v-show="currentIndex === 2">
        <div class="caro-tit">企业数字化转型案例分析</div>
        <div class="caro-con">
          传统企业痛点分析，解决方案介绍，数字化转型应用效果展示
        </div>
      </div>
      <div class="btnBox">
        <div class="btnBoxCla">
          <div class="useBtn" @click="useClick">免费试用</div>
          <div class="proBtn" @click="proClick">产品介绍</div>
        </div>
      </div>
      <div
        @click="clickIcon('up')"
        class="iconDiv icon-left"
        v-show="showArrow"
      >
        <!-- <i class="el-icon-caret-left"></i> -->
        <i class="el-icon-arrow-left"></i>
      </div>
      <div
        @click="clickIcon('down')"
        class="iconDiv icon-right"
        v-show="showArrow"
      >
        <!-- <i class="el-icon-caret-right"></i> -->
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="banner-circle">
        <ul>
          <li
            @click="changeImg(item.id)"
            v-for="item in imgArr"
            :key="item.id"
            :class="item.id === currentIndex ? 'active' : ''"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0, //当前所在图片下标
      showArrow: false,
      timer: null, //定时轮询
      imgArr: [
        { id: 0, url: require("@/assets/swiper-1.png") },
        {
          id: 1,
          url: require("@/assets/swiper-2.png"),
        },
        {
          id: 2,
          url: require("@/assets/swiper-3.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.startInterval();
  },
  methods: {
    //开启定时器
    startInterval() {
      return;
      // 事件里定时器应该先清除在设置，防止多次点击直接生成多个定时器
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex > this.imgArr.length - 1) {
          this.currentIndex = 0;
        }
      }, 3000);
    },
    // 点击左右箭头
    clickIcon(val) {
      if (val === "down") {
        this.currentIndex++;
        if (this.currentIndex === this.imgArr.length) {
          this.currentIndex = 0;
        }
      } else {
        /* 第一种写法
					this.currentIndex--;
					if(this.currentIndex < 0){
						this.currentIndex = this.imgArr.length-1;
					} */
        // 第二种写法
        if (this.currentIndex === 0) {
          this.currentIndex = this.imgArr.length;
        }
        this.currentIndex--;
      }
    },
    // 点击控制圆点
    changeImg(index) {
      this.currentIndex = index;
    },
    //鼠标移入移出控制
    changeInterval(val) {
      if (val) {
        clearInterval(this.timer);
      } else {
        this.startInterval();
      }
    },
    handleEnter() {
      this.showArrow = true;
    },
    handleLeave() {
      this.showArrow = false;
    },
    useClick() {
      this.$parent.zxClick();
    },
    proClick() {
      switch (this.currentIndex) {
        case 0:
          this.$router.push("/product");
          break;
        case 1:
          this.$router.push("/SCRM");
          break;
        case 2:
          this.$router.push("/kwsolution");
          break;
      }
    },
  },
};
</script>

<style scoped >
.caro-text {
  position: absolute;
  /* left: 8.3%; */
  width: 100%;
  top: 154px;
}
.caro-tit {
  font-weight: 500;
  font-size: 44px;
  color: #191c27;
  width: 1200px;
  margin: 0 auto;
}
.caro-con {
  font-size: 18px;
  color: #7e8395;
  margin-top: 22px;
  width: 1200px;
  margin: 0 auto;
}
* {
  padding: 0;
  margin: 0;
}
/* 清除li前面的圆点 */
li {
  list-style-type: none;
}
.showImg {
  position: relative;
  width: 100%;
  height: 525px;
  overflow: hidden;
}
.btnBox {
  position: absolute;
  top: 381px;
  display: flex;
  width: 100%;
}
.btnBoxCla{
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.useBtn {
  width: 168px;
  height: 56px;
  background: #0c5aff;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  line-height: 56px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.proBtn {
  width: 168px;
  height: 56px;
  border: 1px solid #0c5aff;
  border-radius: 8px;
  font-size: 18px;
  color: #0c5aff;
  line-height: 56px;
  text-align: center;
  margin-left: 16px;
  cursor: pointer;
}
/* 轮播图片 */
.showImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 箭头图标 */
.iconDiv {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  /* border: 1px solid #666; */
  border: 1px solid #fff;
  border-radius: 15px;
  /* background-color: rgba(125, 125, 125, 0.2); */
  line-height: 30px;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  background-color: rgba(31, 45, 61, 0.11);
  color: rgba(26, 45, 61, 0.11);
}
.iconDiv:hover {
  background-color: white;
}
.icon-left {
  left: 10px;
}
.icon-right {
  right: 10px;
}

/* 控制圆点 */
.banner-circle {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
}
.banner-circle ul {
  /* margin: 0 50px; */
  height: 100%;
  text-align: center;
}
.banner-circle ul li {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 7px;
  background-color: #fefefe;
  /* background-color: rgba(125, 125, 125, 0.8); */
  cursor: pointer;
}
.active {
  /* background-color: black !important; */
  background-color: rgba(125, 125, 125, 0.8) !important;
}
</style>
